import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TReadBudgetsProfilesResponse = {
    id: string;
    name: string;
    abbreviation: string;
}[];

export type TReadBudgetsProfiles = {
    filter?: string;
    page: number;
    length: number;
    userId: string;
};

export default async (
    params: TReadBudgetsProfiles = {
        page: 1,
        length: 10,
        userId: "",
    }
) => {
    const tempHeaders = {
        "x-user-id": params.userId,
        auth: true,
    };
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/profile`,
        method: "get",
        params: {
            page: params.page,
            length: params.length,
        },
        headers: tempHeaders,
    });
    const { body, statusCode } = httpResponse;

    return handleStatusCases<TReadBudgetsProfilesResponse[]>({
        body,
        statusCode,
    });
};
