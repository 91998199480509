import { Dispatch, useState } from "react";
import Select, {
    components,
    GroupBase,
    MenuListProps,
    SingleValue,
} from "react-select";

import { Icons, theme } from "../../../../assets";
import { useFetch } from "../../../../hooks";
import { TOption } from "../../../../models";
import { createBudgetType } from "../../../../services/budget/type";
import {
    TCreateBudgetTypeResponse,
    TCreateBudgetType,
} from "../../../../services/budget/type/create-budget-type";
import type {
    TReadBudgetTypesResponse,
    TReadBudgetTypes,
} from "../../../../services/budget/type/read-budget-types";
import * as s from "../styled-form-screen";

export type BudgetTypeOption = TReadBudgetTypesResponse & {
    options: TOption[];
};

export type TBudgetTypeSelect = {
    budgetTypes: BudgetTypeOption[];
    setType: Dispatch<string>;
    refetch: (params?: TReadBudgetTypes) => void;
};

export const TypeSelect = ({
    budgetTypes,
    setType,
    refetch,
}: TBudgetTypeSelect) => {
    const [selectedType, setSelectedType] =
        useState<TReadBudgetTypesResponse>();
    const [inputValue, setInputValue] = useState<string>("");
    const createType = useFetch<
        TCreateBudgetType,
        TCreateBudgetTypeResponse,
        any
    >({
        fn: createBudgetType,
        params: { name: inputValue },
        start: false,
    });

    const onChange = (val: SingleValue<TOption>) => {
        if (!val) return;

        setSelectedType(val as any);
        setType(val.value);
    };

    const handleAddActivity = () => {
        if (!inputValue) return;
        createType.onRefresh({
            name: inputValue,
        });
        refetch();
    };

    const CustomMenuList = (
        props: MenuListProps<
            TReadBudgetTypesResponse,
            false,
            GroupBase<TReadBudgetTypesResponse>
        >
    ) => {
        return (
            <components.MenuList {...props}>
                {props.children}
                {inputValue && (
                    <s.AddActivityButton
                        type="button"
                        onClick={handleAddActivity}
                    >
                        {createType.loading ? (
                            <Icons.Loading
                                color={theme.purple500}
                                width="18px"
                                height="18px"
                            />
                        ) : (
                            <Icons.Plus />
                        )}
                        Adicionar tipo de orçamento:
                        <strong> {inputValue}</strong>
                    </s.AddActivityButton>
                )}
            </components.MenuList>
        );
    };

    return (
        <Select
            key={selectedType?.name}
            placeholder={"Tipo do Orçamento"}
            name={"select-activity"}
            onChange={onChange as any}
            options={budgetTypes}
            value={selectedType}
            className={"select-activity"}
            classNamePrefix={"select-activity"}
            captureMenuScroll
            noOptionsMessage={() => "Nenhum tipo foi encontrado."}
            menuPlacement={"auto"}
            styles={{
                menu: (base) => ({
                    ...base,
                }),
                menuList: (base) => ({
                    ...base,
                    maxHeight: "400px !important",
                }),
                groupHeading: (base) => ({
                    ...base,
                    color: theme.gray400,
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    letterSpacing: "0.14px",
                    textTransform: "none",
                    padding: "0px 16px",
                    paddingBottom: "8px",
                }),
                option: (base, { isSelected }) => ({
                    ...base,
                    "&:hover": {
                        backgroundColor: theme.gray50,
                    },
                    backgroundColor: isSelected ? theme.gray100 : "",
                    color: isSelected ? theme.gray900 : theme.gray600,
                    fontSize: "16px",
                    fontWeight: 400,
                    paddingLeft: "32px",
                }),
                placeholder: (base) => ({
                    ...base,
                    color: theme.gray700,
                    fontSize: "16px",
                    fontWeight: 400,
                }),
                input: (base) => ({
                    ...base,
                    color: theme.gray600,
                    fontSize: "20px",
                    fontWeight: 400,
                }),
                noOptionsMessage: (base) => ({
                    ...base,
                    color: theme.gray400,
                    fontSize: "16px",
                    fontWeight: 400,
                    textAlign: "left",
                }),
                singleValue: (base) => ({
                    ...base,
                    color: theme.gray600,
                    fontSize: "20px",
                    fontWeight: 400,
                }),
                control: (base) => ({
                    ...base,
                }),
            }}
            onInputChange={setInputValue}
            components={{ MenuList: CustomMenuList }}
        />
    );
};
