import { ReactElement, type FC } from "react";

import BreadCrumbsMUI from "../../components/molecules/breadcrumpMUI/breadcrumpMUI";
import * as s from "./styled-finances-home";

const rotes = [
    {
        label: "Orçamentos",
        href: "/financial",
    },
];

export const Layout = ({ children }: { children: ReactElement }) => {
    return (
        <s.Container>
            <BreadCrumbsMUI
                breadcrumpRotes={rotes}
                currentRote="Novo Orçamento"
            />
            <s.Title>Orçamentos</s.Title>
            {children}
        </s.Container>
    );
};
