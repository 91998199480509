import styled from "styled-components";

export const Container = styled.div`
    padding-top: 3vh;
`;

export const NoBudgetContainer = styled.div`
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const NoBudgetText = styled.h2`
    margin-top: 5vh;
    color: ${({ theme }) => theme.gray700};
    font-weight: normal;
`;

export const NoBudgetSubtext = styled.span`
    color: ${({ theme }) => theme.gray500};
`;

export const NoBudgetCreationButton = styled.button`
    margin-top: 2vh;
    background-color: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.purple500};
    padding: 1vh;
    border-radius: 6px;
    border: 2px solid ${({ theme }) => theme.purple500};
`;

export const Operations = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const SearchInput = styled.input`
    width: 33%;
    border-bottom: 1px solid ${({ theme }) => theme.gray300};
`;

export const CreationButton = styled.button`
    background-color: ${({ theme }) => theme.purple500};
    color: ${({ theme }) => theme.white};
    padding: 1vh;
    border-radius: 6px;
`;

export const CreationButtonTexts = styled.strong`
    display: flex;
    align-items: center;

    & svg {
        margin-right: 1rem;
    }
`;

export const Table = styled.table`
    width: 100%;
    margin-top: 1.5rem;
    border-collapse: collapse;
    table-layout: auto;

    & tr {
        &:first-child {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }

        &:not(:first-child) {
            border: 1px solid rgba(0, 0, 0, 0.12);
        }
    }

    & th,
    & td {
        padding: 1rem;
        color: var(--text-primary, rgba(62, 55, 78, 0.87));
        font-family: "Noto Sans";
        font-size: 0.875rem;
        font-style: normal;
        line-height: 1.25rem;
        letter-spacing: 0.0088rem;
        text-align: left;
        white-space: nowrap;
        width: 16%;
        & th:last-child,
        & td:last-child {
            text-align: right;
            width: 1%;
        }
    }

    & th {
        font-weight: 700;
    }

    & td {
        font-weight: 400;
    }

    & th:last-child,
    & td:last-child {
        text-align: right;
        width: 1%;
    }
`;

export const StatusText = styled.strong`
    color: ${({ color }) => color};
    font-family: "Noto Sans";
    font-size: 0.75rem;

    font-weight: 600;
    line-height: 1.25rem;
`;

export const NameChip = styled.div`
    background-color: ${({ color }) => color};
    display: flex;
    align-items: center;
    border-radius: 0.3125rem;
    border: 1px solid ${({ theme }) => theme.gray300};
    padding: 8px 4px;
`;

export const NameChipText = styled.strong`
    color: ${({ color }) => color};

    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Noto Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
    letter-spacing: 0.0015rem;
`;

export const NameChipDot = styled.span`
    color: ${({ color }) => color};
    font-size: 36px;
    margin-left: 1vw;
`;

export const PageSelector = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
`;

export const BackButton = styled.button`
    transform: rotate(90deg);
`;

export const ForwardButton = styled.button`
    transform: rotate(270deg);
`;

export const PageButton = styled.button`
    font-size: 16px;
    border-radius: 100%;
    padding-right: 18px;
    padding-left: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
    ${({ name, theme }) =>
        name === "selected" ? `background-color: ${theme.purple50}` : ""}
`;
