import { Dispatch, useState } from "react";
import Select, { SingleValue } from "react-select";

import { theme } from "../../../../assets";
import { TOption } from "../../../../models";
import { TGetClientResponse } from "../../../../services/clients/get-clients";

export type ClientOption = TGetClientResponse & {
    options: TOption[];
};

export type TBudgetTypeSelect = {
    clients: ClientOption[];
    setClient: Dispatch<string>;
};

export const ClientSelect = ({ clients, setClient }: TBudgetTypeSelect) => {
    const [selectedClient, setSelectedClient] = useState<TGetClientResponse>();

    const onChange = (val: SingleValue<TOption>) => {
        if (!val) return;

        setSelectedClient(val as any);
        setClient(val.value);
    };

    return (
        <Select
            key={selectedClient?.name}
            placeholder={"Nome do Cliente"}
            name={"select-activity"}
            onChange={onChange as any}
            options={clients}
            value={selectedClient}
            className={"select-activity"}
            classNamePrefix={"select-activity"}
            captureMenuScroll
            noOptionsMessage={() => "Nenhum cliente foi encontrado."}
            menuPlacement={"auto"}
            styles={{
                menu: (base) => ({
                    ...base,
                }),
                menuList: (base) => ({
                    ...base,
                    maxHeight: "400px !important",
                }),
                groupHeading: (base) => ({
                    ...base,
                    color: theme.gray400,
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    letterSpacing: "0.14px",
                    textTransform: "none",
                    padding: "0px 16px",
                    paddingBottom: "8px",
                }),
                option: (base, { isSelected }) => ({
                    ...base,
                    "&:hover": {
                        backgroundColor: theme.gray50,
                    },
                    backgroundColor: isSelected ? theme.gray100 : "",
                    color: isSelected ? theme.gray900 : theme.gray600,
                    fontSize: "16px",
                    fontWeight: 400,
                    paddingLeft: "32px",
                }),
                placeholder: (base) => ({
                    ...base,
                    color: theme.gray700,
                    fontSize: "16px",
                    fontWeight: 400,
                }),
                input: (base) => ({
                    ...base,
                    color: theme.gray600,
                    fontSize: "20px",
                    fontWeight: 400,
                }),
                noOptionsMessage: (base) => ({
                    ...base,
                    color: theme.gray400,
                    fontSize: "16px",
                    fontWeight: 400,
                    textAlign: "left",
                }),
                singleValue: (base) => ({
                    ...base,
                    color: theme.gray600,
                    fontSize: "20px",
                    fontWeight: 400,
                }),
                control: (base) => ({
                    ...base,
                }),
            }}
        />
    );
};
