import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Icons, theme } from "../../../assets";
import {
    BudgetStatus,
    TReadBudgetsResponse,
} from "../../../services/budget/read-budgets";
import { BudgetTable, type DisplayedBudgetStatus } from "./budget-table";
import * as s from "./styled-tab-budget";

type TTabBudget = {
    budgets: TReadBudgetsResponse[];
    filterState: [string, React.Dispatch<React.SetStateAction<string>>];
    budgetsCountState: [number, React.Dispatch<React.SetStateAction<number>>];
    currentPageState: [number, React.Dispatch<React.SetStateAction<number>>];
};

export const NoBudget = () => {
    const navigate = useNavigate();

    return (
        <s.Container>
            <s.NoBudgetContainer>
                <BudgetTable budgets={[]} />
                <s.NoBudgetText>Nenhum Orçamento criado</s.NoBudgetText>
                <s.NoBudgetSubtext>
                    Comece um orçamento clicando no botão abaixo
                </s.NoBudgetSubtext>
                <s.NoBudgetCreationButton
                    onClick={() => navigate("/financial/budget/create")}
                >
                    <s.CreationButtonTexts>
                        <Icons.Plus color={theme.purple500} strokeWidth="3" />
                        CRIAR ORÇAMENTO
                    </s.CreationButtonTexts>
                </s.NoBudgetCreationButton>
            </s.NoBudgetContainer>
        </s.Container>
    );
};

export const TabBudget = ({
    budgets,
    budgetsCountState: [budgetsCount, setBudgetsCount],
    filterState,
    currentPageState: [currentPage, setCurrentPage],
}: TTabBudget) => {
    const [filter, setFilter] = filterState;
    const [pagesList, setPagesList] = useState<number[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const arrayLen = budgetsCount ? Math.ceil((budgetsCount ?? 1) / 10) : 1;

        setPagesList(
            Array(arrayLen)
                .fill(0)
                .map((_, i) => i + 1)
        );
    }, [budgetsCount]);

    const translateStatus = (
        status: BudgetStatus
    ): DisplayedBudgetStatus | "" => {
        const TRANSLATE_STATUS = {
            approved: "Aprovado",
            progress: "Andamento",
            refused: "Reprovado",
            waiting: "Aguardando",
        } satisfies Record<BudgetStatus, DisplayedBudgetStatus>;

        return TRANSLATE_STATUS[status] ?? "";
    };

    const translateDate = (rawDate: string): string => {
        return new Date(rawDate).toLocaleDateString("pt-br");
    };

    if (budgets?.length === 0 && filter === "") return <NoBudget />;
    // const testingNoBudget = true;
    // if (testingNoBudget) return <NoBudget />;

    return (
        <s.Container>
            <s.Operations>
                <TextField
                    label="Pesquise pelo nome do Orçamento"
                    variant="standard"
                    sx={{ width: "25%", backgroundColor: "transparent" }}
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Search
                                    sx={{
                                        color: theme.gray800,
                                    }}
                                />
                            </InputAdornment>
                        ),
                    }}
                />
                <s.CreationButton
                    onClick={() => navigate("/financial/budget/create")}
                >
                    <s.CreationButtonTexts>
                        <Icons.Plus color="white" strokeWidth="3" />
                        CRIAR ORÇAMENTO
                    </s.CreationButtonTexts>
                </s.CreationButton>
            </s.Operations>
            <BudgetTable
                budgets={budgets.map((budget) => ({
                    name: budget.name,
                    color: budget.color,
                    secondary_color: budget.secondary_color,
                    type: budget.type.name,
                    client: budget.client.name,
                    status: translateStatus(budget.status),
                    created_at: translateDate(budget.created_at),
                }))}
            />
            <s.PageSelector>
                <s.BackButton
                    onClick={() => {
                        const prevPage = currentPage - 1;
                        if (pagesList.includes(prevPage))
                            setCurrentPage(prevPage);
                    }}
                >
                    <Icons.Chevron
                        width="3"
                        color={currentPage === 1 ? theme.gray300 : ""}
                    />
                </s.BackButton>
                {pagesList.map((page) => {
                    return (
                        <s.PageButton
                            name={page === currentPage ? "selected" : ""}
                            onClick={() => setCurrentPage(page)}
                        >
                            {page}
                        </s.PageButton>
                    );
                })}
                <s.ForwardButton
                    onClick={() => {
                        const nextPage = currentPage + 1;
                        if (pagesList.includes(nextPage))
                            setCurrentPage(nextPage);
                    }}
                >
                    <Icons.Chevron
                        color={
                            currentPage === pagesList.length
                                ? theme.gray300
                                : ""
                        }
                        width="3"
                    />
                </s.ForwardButton>
            </s.PageSelector>
        </s.Container>
    );
};
