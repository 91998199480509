import { CheckOutlined } from "@mui/icons-material";
import { Divider } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { theme } from "../../../assets";
import { useUser } from "../../../context";
import { TCreateBudget } from "../../../services/budget/create-budget";
import * as Budgets from "../../../services/budget/index";
import { Layout } from "../layout";
import { FirstStep, FirstStepData } from "./first-step/first-step";
import { SecondStep, SecondStepData } from "./second-step/second-step";
import * as s from "./styled-form-screen";
import {
    BudgetProfiles,
    ThirdStep,
    ThirdStepData,
} from "./third-step/third-step";

export type StepData = FirstStepData | SecondStepData | ThirdStepData;

export type TBudgetProfiles =
    | {
          id: string;
          name: string;
          abbreviation: string;
      }[]
    | [];

export const Form = () => {
    const [step, setStep] = useState<number>(1);
    const { user } = useUser();
    const navigate = useNavigate();
    const [fullData, setFullData] = useState<StepData>({
        name: "",
        description: "",
        clientId: "",
        budgetTypeId: "",
        color: "#03BA78",
        secondaryColor: "#00000",
        levelConfigs: [],
        budgetProfiles: [],
    });

    const createBudget = useMutation({
        mutationKey: ["createBudget"],
        mutationFn: Budgets.createBudget,
        onSuccess() {
            toast.success("Projetos importados com sucesso!");
            navigate("/financial");
        },
    });

    const prevStep = () => {
        if (step <= 1) return;
        setStep(step - 1);
    };

    const nextStep = (stepData: StepData) => {
        if (step >= 3) {
            const budgetData =
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                stepData?.budgetProfiles as unknown as BudgetProfiles[];
            const budgetProfiles = budgetData.map((data) => {
                return {
                    profileId: data.id_e,
                    responsible: data.responsible?.id,
                    costPerHour: data.cost,
                };
            });
            const data = {
                ...fullData,
                budgetProfiles,
                organizationId: user.Organization.id_e,
            };
            return createBudget.mutate({
                body: data,
                user_id: user.id_e,
            } as unknown as TCreateBudget);
        }
        setFullData({
            ...fullData,
            ...stepData,
        });
        return setStep(step + 1);
    };

    return (
        <>
            <s.StepSelector>
                <s.Step className={step >= 1 ? "active" : ""}>
                    {step > 1 ? (
                        <CheckOutlined
                            sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                backgroundColor: theme.primary.main,
                                borderRadius: "50%",
                                color: theme.white,
                            }}
                        />
                    ) : (
                        <div>1</div>
                    )}
                    <span>Dados Iniciais</span>
                </s.Step>
                <Divider
                    sx={{
                        height: "0.0625rem",
                        width: "35%",
                    }}
                />
                <s.Step className={step >= 2 ? "active" : ""}>
                    {step > 2 ? (
                        <CheckOutlined
                            sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                backgroundColor: theme.primary.main,
                                borderRadius: "50%",
                                color: theme.white,
                            }}
                        />
                    ) : (
                        <div>2</div>
                    )}
                    <span>Configurar Escopo</span>
                </s.Step>
                <Divider
                    sx={{
                        height: "0.0625rem",
                        width: "35%",
                    }}
                />
                <s.Step className={step === 3 ? "active" : ""}>
                    <div>3</div>
                    <span>Perfis de Atividade</span>
                </s.Step>
            </s.StepSelector>
            <s.Form>
                {step === 1 && (
                    <FirstStep
                        prevStep={prevStep}
                        nextStep={nextStep}
                        step={step}
                    />
                )}
                {step === 2 && (
                    <SecondStep
                        prevStep={prevStep}
                        nextStep={nextStep}
                        step={step}
                    />
                )}
                {step === 3 && (
                    <ThirdStep
                        prevStep={prevStep}
                        nextStep={nextStep}
                        step={step}
                    />
                )}
            </s.Form>
        </>
    );
};

export const FormScreen = () => {
    return <Layout children={Form()} />;
};
