import { MoreVert } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import Tag from "../../../components/atoms/tag/tag";
import * as s from "./styled-tab-budget";

export type DisplayedBudgetStatus =
    | "Andamento"
    | "Aprovado"
    | "Aguardando"
    | "Reprovado";

type BudgetForTable = {
    name: string;
    created_at: string;
    status: DisplayedBudgetStatus | "";
    client: string;
    hours?: number;
    cost?: number;
    type: string;
    color: string;
    secondary_color: string;
};

type TBudgetTable = {
    budgets: BudgetForTable[];
};

export const BudgetTable = ({ budgets }: TBudgetTable) => {
    const getStatusColor = (status: DisplayedBudgetStatus | ""): string => {
        if (status === "") return "#ffff";

        const STATUS_COLOR_MAP = {
            Aguardando: "#0288D1",
            Andamento: "#EF6C00",
            Aprovado: "#2E7D32",
            Reprovado: "#D32F2F",
        } satisfies Record<DisplayedBudgetStatus, string>;

        return STATUS_COLOR_MAP[status] ?? "#ffff";
    };
    return (
        <>
            <s.Table>
                <tr>
                    <th>Nome</th>
                    <th>Data</th>
                    <th>Status</th>
                    <th>Horas</th>
                    <th>Custo</th>
                    <th>Cliente</th>
                    <th>Tipo</th>
                    <th></th>
                </tr>
                {budgets.map((budget) => {
                    return (
                        <tr>
                            <td>
                                <Tag
                                    maxWidth="14.75rem"
                                    color={budget.color}
                                    isFavorite={false}
                                >
                                    {budget.name}
                                </Tag>
                            </td>
                            <td>{budget.created_at}</td>
                            <td>
                                <s.StatusText
                                    color={getStatusColor(budget.status)}
                                >
                                    {budget.status}
                                </s.StatusText>
                            </td>
                            <td>{budget.hours}</td>
                            <td>{budget.cost}</td>
                            <td>{budget.client}</td>
                            <td>{budget.type}</td>
                            <td>
                                <IconButton>
                                    <MoreVert />
                                </IconButton>
                            </td>
                        </tr>
                    );
                })}
            </s.Table>
        </>
    );
};
