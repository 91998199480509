import { styled } from "styled-components";

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    & tr {
        border-bottom: 1px solid ${({ theme }) => theme.gray300};
    }

    & th {
        text-align: initial;
        color: ${({ theme }) => theme.gray500};
        padding: 0.5rem;
    }

    & td {
        padding: 1.5rem 0.5rem 1.5rem 0.5rem;
    }
`;

export const NoProfilesContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const NoProfilesText = styled.h2`
    margin-top: 1vh;
    color: ${({ theme }) => theme.gray700};
    font-weight: normal;
`;

export const NoProfilesSubtext = styled.span`
    color: ${({ theme }) => theme.gray500};
`;

export const ProfileCreationButton = styled.button`
    background-color: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.purple500};
    padding: 1vh;
    border-radius: 6px;
    align-self: flex-start;
`;

export const CreationButtonTexts = styled.strong`
    display: flex;
    align-items: center;

    & svg {
        margin-right: 1rem;
    }
`;

export const DialogBox = styled.div`
    padding: 1rem;
`;
