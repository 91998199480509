import { axiosHttpClient } from "../../axios";
import handleStatusCases from "../../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TCreateBudgetType = {
    name: string;
};

export type TCreateBudgetTypeResponse = {
    id_e: string;
    name: string;
};

export default async (body: TCreateBudgetType = { name: "" }) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/type`,
        method: "post",
        body,
        headers: { auth: true },
    });

    return handleStatusCases<TCreateBudgetTypeResponse>(httpResponse);
};
