import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TCreateBudget = {
    body: {
        name: string;
        color: string;
        secondaryColor: string;
        description: string;
        clientId: string;
        budgetTypeId: string;
        organizationId: string;
        levelConfigs: [
            {
                name: string;
                levelOrder: number;
            }
        ];
        budgetProfiles: {
            profileId: string;
            responsible: string;
            costPerHour: number;
        }[];
    };
    user_id: string;
};

export default async ({ body: bodyReq, user_id }: TCreateBudget) => {
    const tempHeaders = {
        auth: true,
        "x-user-id": user_id,
    };

    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget`,
        method: "post",
        body: bodyReq,
        headers: tempHeaders,
    });
    const { body, statusCode } = httpRequest;

    return handleStatusCases({ body, statusCode });
};
