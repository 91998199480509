import { yupResolver } from "@hookform/resolvers/yup";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Stack,
    Typography,
} from "@mui/material";
import { Dispatch } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { theme } from "../../../../assets";
import CustomTextField from "../../../../components/molecules/custom-textfield/custom-textfield";
import * as s from "./styled-third-step";

const validation = yup.object().shape({
    name: yup.string(),
    abbreviation: yup
        .string()
        .max(4, "A abreviação deve conter no máximo 4 carácteres"),
});

export type NewProfile = {
    name: string;
    abbreviation: string;
};

export const NewProfileDialog = ({
    open,
    setOpen,
    addProfile,
}: {
    open: boolean;
    setOpen: Dispatch<boolean>;
    addProfile: (newProfile: NewProfile) => void;
}) => {
    const { control, handleSubmit } = useForm<NewProfile>({
        defaultValues: {
            name: "",
            abbreviation: "",
        },
        resolver: yupResolver(validation),
    });

    const submit = (data: NewProfile) => {
        addProfile(data);
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open}>
            <form onSubmit={handleSubmit(submit)}>
                <s.DialogBox>
                    <Typography
                        variant="h6"
                        sx={{ marginTop: "1vh", p: "1rem 1.5rem" }}
                    >
                        Adicionar Perfil
                    </Typography>

                    <DialogContent>
                        <Stack
                            sx={{
                                gap: "1.5rem",
                            }}
                        >
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    color: theme.gray500,
                                }}
                            >
                                Perfis de atividade representam categorias
                                específicas de trabalhos dentro de um projeto,
                                organizando as funções e responsabilidades de
                                forma clara.
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    gap: "1rem",
                                }}
                            >
                                <CustomTextField
                                    autoFocus
                                    required
                                    sx={{
                                        margin: "dense",
                                    }}
                                    control={control}
                                    name="name"
                                    label="Nome do Perfil"
                                    fullWidth
                                    variant="outlined"
                                />
                                <Stack>
                                    <CustomTextField
                                        required
                                        name="abbreviation"
                                        sx={{
                                            margin: "dense",
                                        }}
                                        control={control}
                                        label="Abreviação*"
                                        fullWidth
                                        variant="outlined"
                                        maxCharacters={4}
                                    />
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: theme.gray500,
                                            pl: "0.625rem",
                                        }}
                                    >
                                        Máx. 4 Caracteres
                                    </Typography>
                                </Stack>
                            </Box>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="text"
                            onClick={handleCancel}
                            sx={{
                                color: theme.purple500,
                                fontWeight: 500,
                                fontSize: "0.875rem",
                            }}
                        >
                            CANCELAR
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                color: theme.white,
                                fontWeight: 500,
                                fontSize: "0.875rem",
                            }}
                        >
                            Adicionar
                        </Button>
                    </DialogActions>
                </s.DialogBox>
            </form>
        </Dialog>
    );
};
