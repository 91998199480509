import IconAlertCircle from "./icon-alert-circle";
import AmexCard from "./icon-amex-card";
import IconArchive from "./icon-archive";
import Arrow from "./icon-arrow";
import ArrowBack from "./icon-arrow-back";
import ArrowDiagonal from "./icon-arrow-diagonal";
import ArrowOrder from "./icon-arrow-order";
import IconArrowWithDirectionOption from "./icon-arrow-with-direction-option";
import ArrowsCircle from "./icon-arrows-circle";
import ArrowsRetry from "./icon-arrows-retry";
import AttachFile from "./icon-attach-file";
import BellOn from "./icon-bell-on";
import Bill from "./icon-bill";
import Book from "./icon-book";
import Briefcase from "./icon-briefcase";
import Bug from "./icon-bug";
import BuildingOffice from "./icon-building-office";
import Calcado from "./icon-calcado";
import CalendarOutline from "./icon-calendar-outline";
import Calendario from "./icon-calendario";
import Camera from "./icon-camera";
import Car from "./icon-car";
import ChartBar from "./icon-chart-bar";
import ChartColumns from "./icon-chart-columns";
import Chat from "./icon-chat";
import ChatDisabled from "./icon-chat-disabled";
import Check from "./icon-check";
import CheckCircle from "./icon-check-circle";
import IconCheckCircle2 from "./icon-check-circle-2";
import Checked from "./icon-checked";
import Chevron from "./icon-chevron";
import ChevronDecrease from "./icon-chevron-decrease";
import ChevronRounded from "./icon-chevron-rounded";
import iconCircle from "./icon-circle";
import CirclePulsating from "./icon-circle-pulsating";
import CleaningBrush from "./icon-cleaning-brush";
import Clock from "./icon-clock";
import ClockOutline from "./icon-clock-outline";
import Close from "./icon-close";
import CogOutline from "./icon-cog-outline";
import CollapseArrow from "./icon-collapse-arrow";
import DashboardReference from "./icon-dashboard-reference";
import Dev from "./icon-dev";
import Document from "./icon-document";
import DocumentDetailed from "./icon-document-detailed";
import DocumentList from "./icon-document-list";
import DocumentText from "./icon-document-text";
import Dollar from "./icon-dollar";
import IconDotOutline from "./icon-dot-outiline";
import Download from "./icon-download";
import Drag from "./icon-drag";
import Duplicate from "./icon-duplicate";
import EditOutline from "./icon-edit-outline";
import EloCard from "./icon-elo-card";
import Email from "./icon-email";
import EmailForward from "./icon-email-forward";
import EmailOpen from "./icon-email-open";
import EmailUnread from "./icon-email-unread";
import Excel from "./icon-excel";
import ExclamationFilled from "./icon-exclamation-filled";
import ExclamationOutline from "./icon-exclamation-outline";
import ExpandContent from "./icon-expand-content";
import Eye from "./icon-eye";
import Favorite from "./icon-favorite";
import Filler from "./icon-filler";
import FillerOutlined from "./icon-filler-outlined";
import FilterList from "./icon-filter-list";
import Google from "./icon-google";
import GPS from "./icon-gps";
import Hand from "./icon-hand";
import HandOutline from "./icon-hand-outline";
import Health from "./icon-health";
import HiperCard from "./icon-hiper-card";
import History from "./icon-history";
import HorizontalBarCircle from "./icon-horizontalbar-circle";
import Info from "./icon-info";
import Instagram from "./icon-instagram";
import Key from "./icon-key";
import Lamp from "./icon-lamp";
import Linkedin from "./icon-linkedin";
import Loading from "./icon-loading";
import LoadingSpinCircle from "./icon-loading-spin-circle";
import Location from "./icon-location";
import iconLockClosed from "./icon-lock-closed";
import iconLockClosedFill from "./icon-lock-closed-fill";
import IconLockOpen from "./icon-lock-open";
import Logout from "./icon-logout";
import MailOutlined from "./icon-mail-outlined";
import MasterCard from "./icon-master-card";
import Medal from "./icon-medal";
import MedicineChest from "./icon-medicine-chest";
import Membership from "./icon-membership";
import MilitaryMedal from "./icon-military-medal";
import More from "./icon-more";
import MousePointer from "./icon-mouse-pointer";
import N from "./icon-n";
import Paper from "./icon-paper";
import PaperClip from "./icon-paper-clip";
import PaperWhite from "./icon-paper-white";
import PDF from "./icon-pdf";
import PencilOutline from "./icon-pencil-outline";
import Phone from "./icon-phone";
import PhoneOutlined from "./icon-phone-outlined";
import PinkChatBox from "./icon-pink-chat-box";
import PinkChatBox2 from "./icon-pink-chat-box2";
import Play from "./icon-play";
import Plus from "./icon-plus";
import Portrait from "./icon-portrait";
import QuestionMarkOutline from "./icon-question-mark-outline";
import Repeat from "./icon-repeat";
import Rocket from "./icon-rocket";
import Save from "./icon-save";
import SearchOutline from "./icon-search-outline";
import Send from "./icon-send";
import SendFilled from "./icon-send-filled";
import SenhaFechado from "./icon-senha-fechado";
import Settings from "./icon-settings";
import Star from "./icon-star";
import Stop from "./icon-stop";
import Tag from "./icon-tag";
import Telefone from "./icon-telefone";
import Template from "./icon-template";
import Trash from "./icon-trash";
import TrendingUp from "./icon-trending-up";
import Unarchive from "./icon-unarchive";
import Upload from "./icon-upload";
import User from "./icon-user";
import UserAdd from "./icon-user-add";
import { IconUserAdd2 } from "./icon-user-add-2";
import UserGroup from "./icon-user-group";
import UserManage from "./icon-user-manage";
import UserPlus from "./icon-user-plus";
import UserTrack from "./icon-user-track";
import VideoCamera from "./icon-video-camera";
import VisaCard from "./icon-visa-card";
import Warning from "./icon-warning";
import WarningInfo from "./icon-warning-info";
import WarningSuccess from "./icon-warning-success";
import WelcomeBox from "./icon-welcome-box";
import Whatsapp from "./icon-whatsapp";
import iconWhatsappFilled from "./icon-whatsapp-filled";
import Worker from "./icon-worker";
import XOutline from "./icon-x-outline";
import XSolid from "./icon-x-solid";

export const Icons = {
    Arrow,
    ArrowOrder,
    ArrowBack,
    AttachFile,
    Bug,
    LoadingSpinCircle,
    ArrowDiagonal,
    ArrowsRetry,
    BellOn,
    WelcomeBox,
    Bill,
    Briefcase,
    BuildingOffice,
    Book,
    Chat,
    Calcado,
    ChatDisabled,
    CalendarOutline,
    IconAlertCircle,
    Instagram,
    Calendario,
    Repeat,
    Camera,
    Car,
    ChartBar,
    ChartColumns,
    CheckCircle,
    Checked,
    Check,
    Chevron,
    ChevronRounded,
    Clock,
    TrendingUp,
    ClockOutline,
    Close,
    CogOutline,
    CollapseArrow,
    DashboardReference,
    Dev,
    Document,
    ChevronDecrease,
    DocumentDetailed,
    DocumentList,
    DocumentText,
    Dollar,
    Download,
    Drag,
    IconDotOutline,
    EditOutline,
    Email,
    EmailForward,
    EmailOpen,
    EmailUnread,
    Eye,
    ExclamationFilled,
    ExclamationOutline,
    Favorite,
    Filler,
    FillerOutlined,
    GPS,
    Hand,
    HandOutline,
    Health,
    iconCircle,
    iconLockClosed,
    iconLockClosedFill,
    IconLockOpen,
    Info,
    HorizontalBarCircle,
    Key,
    Lamp,
    Linkedin,
    Logout,
    Settings,
    Location,
    MailOutlined,
    MilitaryMedal,
    Medal,
    MedicineChest,
    ArrowsCircle,
    More,
    Phone,
    Membership,
    MousePointer,
    N,
    Paper,
    PaperClip,
    PencilOutline,
    PinkChatBox,
    Unarchive,
    PinkChatBox2,
    Play,
    Plus,
    Portrait,
    QuestionMarkOutline,
    Save,
    SendFilled,
    SearchOutline,
    History,
    PaperWhite,
    PDF,
    PhoneOutlined,
    Rocket,
    Send,
    SenhaFechado,
    Stop,
    Duplicate,
    Telefone,
    IconCheckCircle2,
    IconArchive,
    Trash,
    Tag,
    IconUserAdd2,
    Template,
    Upload,
    User,
    UserAdd,
    UserGroup,
    UserManage,
    UserPlus,
    UserTrack,
    Warning,
    WarningInfo,
    WarningSuccess,
    Whatsapp,
    XOutline,
    XSolid,
    Google,
    IconArrowWithDirectionOption,
    EloCard,
    AmexCard,
    MasterCard,
    VisaCard,
    HiperCard,
    iconWhatsappFilled,
    ExpandContent,
    FilterList,
    Loading,
    CleaningBrush,
    Excel,
    Star,
    VideoCamera,
    CirclePulsating,
    Worker,
};
