export const lightTheme = {
    textPrimary: "#021547",
    white: "#FFFFFF",
    white2: "#FCFCFC",
    white3: "#D9D9D9",
    black: "#000000",
    lightBlack: "#666666",
    gray900: "#261E38",
    gray800: "#3E374E",
    gray700: "#565064",
    gray600: "#6E697A",
    gray500: "#868290",
    gray400: "#9E9BA7",
    gray300: "#B7B4BD",
    gray200: "#CFCDD3",
    gray100: "#E7E6E9",
    gray50: "#F3F3F4",
    pink900: "#562A81",
    pink800: "#702C9D",
    pink700: "#932CC1",
    pink600: "#B93BDC",
    pink500: "#E155F3",
    pink400: "#E87FF6",
    pink300: "#EE9FF8",
    pink200: "#F2B7FA",
    pink100: "#F5C9FB",
    pink50: "#FCEEFE",
    purple900: "#2A2644",
    purple800: "#342A52",
    purple700: "#402F64",
    purple600: "#503479",
    purple500: "#663398",
    purple400: "#8C53C5",
    purple300: "#A97ED4",
    purple200: "#BE9EDE",
    purple100: "#CEB6E7",
    purple50: "#F6EDFF",
    red: "#D64747",
    redError: "#E32D2D",
    softRed: "#FDEDED",
    green1: "#278027",
    greenDone: "#57E191",
    orange: "#F28500",
    orangeWarning: "#ED6C02",
    lightYellow: "#FFF4E5",
    lightest: {
        green1: "#F4F9F4",
        pink1: "#FDF2FD",
        orange: "#FEF9F2",
    },
    light: {
        green1: "#E0EDE0",
        orange: "#FDEDDA",
        pink1: "#FADAFA",
    },
    pink1: "#DD04DD",
    yellow: "#F2C94C",
    lightGreen: "#E9F2E9",
    lightGray: "#f3f3f3",
    background: {
        gradient1:
            "linear-gradient(199.25deg, #873A8E 14.99%, #523F92 67.89%, #3A235A 127.8%)",
        gradient2: "linear-gradient(47.94deg, #3C286F 19.6%, #4A308A 77.32%)",
        gradient3:
            "linear-gradient(145.51deg, #BBF1FF 13.35%, #30B3D5 14.04%, #52BCD9 14.91%, #70C8DE 15.63%, #6DC6DD 17.08%, #63C2DC 17.6%, #4FBBD9 17.96%, #27B2D5 18.26%, #1EB0D4 18.3%, #39B3D6 19.38%, #39B3D6 19.39%, #3EB4D6 19.4%, #6FBDDC 21.27%, #A4CBE6 23.7%, #D6DBF0 26.3%, #CED9EF 28.05%, #B7D5EA 30.97%, #8ACDE2 34.7%, #70C8DE 36.2%, #6AB0D3 38.28%, #6591C3 41.33%, #6475B2 44.31%, #635EA4 47.19%, #624D9A 49.9%, #614394 52.4%, #613F92 54.49%, #4A3A8F 59.83%, #3F388D 63.24%, #3C4695 63.39%, #3360A5 63.7%, #2973B0 64%, #1F81B8 64.29%, #1789BC 64.55%, #148CBE 64.77%, #A5DAF0 72.77%, #90D2E8 73.83%, #79CAE0 75.21%, #70C8DE 76.19%, #679ECA 79.92%, #636DAD 84.2%, #624C99 87.25%, #613F92 88.77%)",
        gradient4: "linear-gradient(294.62deg, #67129B -3.79%, #2D054D 90.01%)",
    },
    levels: {
        1: "#4685BF",
        2: "#4685BF",
        3: "#C3863E",
        4: "#C3863E",
        5: "#E4B558",
        6: "#E4B558",
        7: "#C3463E",
        8: "#C3463E",
        9: "#000000",
        10: "#000000",
    },
    primary: {
        main: "#A97ED4",
    },
} as const;

export const darkTheme = {
    textPrimary: "#021547",
    white: "#FFFFFF",
    white2: "#FCFCFC",
    white3: "#D9D9D9",
    black: "#000000",
    lightBlack: "#666666",
    gray900: "#261E38",
    gray800: "#3E374E",
    gray700: "#565064",
    gray600: "#6E697A",
    gray500: "#868290",
    gray400: "#9E9BA7",
    gray300: "#B7B4BD",
    gray200: "#CFCDD3",
    gray100: "#E7E6E9",
    gray50: "#F3F3F4",
    pink900: "#562A81",
    pink800: "#702C9D",
    pink700: "#932CC1",
    pink600: "#B93BDC",
    pink500: "#E155F3",
    pink400: "#E87FF6",
    pink300: "#EE9FF8",
    pink200: "#F2B7FA",
    pink100: "#F5C9FB",
    pink50: "#FCEEFE",
    purple900: "#2A2644",
    purple800: "#342A52",
    purple700: "#402F64",
    purple600: "#503479",
    purple500: "#663398",
    purple400: "#8C53C5",
    purple300: "#A97ED4",
    purple200: "#BE9EDE",
    purple100: "#CEB6E7",
    purple50: "#F6EDFF",
    red: "#D64747",
    redError: "#E32D2D",
    softRed: "#FDEDED",
    green1: "#278027",
    greenDone: "#57E191",
    orange: "#F28500",
    lightGreen: "#E9F2E9",
    lightGray: "#f3f3f3",
    lightest: {
        green1: "#F4F9F4",
        pink1: "#FDF2FD",
        orange: "#FEF9F2",
    },
    light: {
        green1: "#E0EDE0",
        orange: "#FDEDDA",
        pink1: "#FADAFA",
    },

    lightYellow: "#FFF4E5",
    orangeWarning: "#ED6C02",
    pink1: "#DD04DD",
    yellow: "#F2C94C",

    background: {
        gradient1:
            "linear-gradient(199.25deg, #873A8E 14.99%, #523F92 67.89%, #3A235A 127.8%)",
        gradient2: "linear-gradient(47.94deg, #3C286F 19.6%, #4A308A 77.32%)",
        gradient3:
            "linear-gradient(145.51deg, #BBF1FF 13.35%, #30B3D5 14.04%, #52BCD9 14.91%, #70C8DE 15.63%, #6DC6DD 17.08%, #63C2DC 17.6%, #4FBBD9 17.96%, #27B2D5 18.26%, #1EB0D4 18.3%, #39B3D6 19.38%, #39B3D6 19.39%, #3EB4D6 19.4%, #6FBDDC 21.27%, #A4CBE6 23.7%, #D6DBF0 26.3%, #CED9EF 28.05%, #B7D5EA 30.97%, #8ACDE2 34.7%, #70C8DE 36.2%, #6AB0D3 38.28%, #6591C3 41.33%, #6475B2 44.31%, #635EA4 47.19%, #624D9A 49.9%, #614394 52.4%, #613F92 54.49%, #4A3A8F 59.83%, #3F388D 63.24%, #3C4695 63.39%, #3360A5 63.7%, #2973B0 64%, #1F81B8 64.29%, #1789BC 64.55%, #148CBE 64.77%, #A5DAF0 72.77%, #90D2E8 73.83%, #79CAE0 75.21%, #70C8DE 76.19%, #679ECA 79.92%, #636DAD 84.2%, #624C99 87.25%, #613F92 88.77%)",
        gradient4: "linear-gradient(294.62deg, #67129B -3.79%, #2D054D 90.01%)",
    },
    levels: {
        1: "#4685BF",
        2: "#4685BF",
        3: "#C3863E",
        4: "#C3863E",
        5: "#E4B558",
        6: "#E4B558",
        7: "#C3463E",
        8: "#C3463E",
        9: "#000000",
        10: "#000000",
    },
    primary: {
        main: "#A97ED4",
    },
} as const;

export const chooseTheme = () => {
    if (localStorage.getItem("tema") === "light") return lightTheme;

    const themesAreCorrect =
        Object.keys(lightTheme).length !== Object.keys(darkTheme).length;
    if (themesAreCorrect && process.env.NODE_ENV === "development")
        throw new Error(
            "Os temas não possuem a mesma quantidade de propriedades"
        );

    return darkTheme;
};
