import { Button } from "@mui/material";

import { theme } from "../../../assets";
import * as s from "./styled-form-screen";

export type TStepProps = {
    step: number;
    handlePrevStep: () => void;
    handleNextStep: () => void;
};

export function StepButtons({
    step,
    handleNextStep,
    handlePrevStep,
}: TStepProps) {
    return (
        <s.FormGrouper className="flex-end">
            <Button
                variant="outlined"
                disabled={step <= 1}
                sx={{
                    border: "0px",
                    color: theme.purple600,
                    fontFamily: "revert",
                    "&:hover": {
                        border: 0,
                        background: "transparent",
                    },
                }}
                onClick={() => handlePrevStep()}
            >
                VOLTAR
            </Button>
            <Button
                variant="contained"
                disabled={step >= 4}
                sx={{
                    backgroundColor: theme.purple500,
                    "&:hover": {
                        backgroundColor: theme.purple600,
                    },
                }}
                onClick={() => handleNextStep()}
            >
                AVANÇAR
            </Button>
        </s.FormGrouper>
    );
}
