import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { Icons, theme } from "../../../../assets";
import { useUser } from "../../../../context";
import { axiosHttpClient } from "../../../../services/axios";
import * as Budgets from "../../../../services/budget/index";
import {
    TReadBudgetsProfiles,
    TReadBudgetsProfilesResponse,
} from "../../../../services/budget/read-profiles";
import { TBudgetProfiles } from "../form-screen";
import { StepButtons } from "../step-buttons";
import * as sScreen from "../styled-form-screen";
import { NewProfile, NewProfileDialog } from "./new-profile-dialog";
import { NewProfilesTable } from "./new-profiles-table";
import { ResponsibleDialog } from "./responsible-dialog";
import * as s from "./styled-third-step";

const { REACT_APP_BASE_URL } = process.env;

export type BudgetProfiles = {
    id_e: string;
    name: string;
    abbreviation: string;
    responsible: {
        name: string;
        id: string;
        avatar: string;
    } | null;
    cost: number;
    isSelected: boolean;
};

export type NewResponsible = {
    id?: string;
    name?: string;
    avatar?: string;
};

export type ThirdStepData = {
    budgetProfiles: BudgetProfiles[];
};

export type TStepProps<Payload> = {
    step: number;
    prevStep: () => void;
    nextStep: (currentData: Payload) => void;
};

type TThirdStepProps = TStepProps<ThirdStepData>;

const NoProfiles = () => {
    return (
        <s.NoProfilesContainer>
            <s.NoProfilesText>
                Você ainda não possui Perfils para atuar neste orçamento.
            </s.NoProfilesText>
            <s.NoProfilesSubtext>
                Configure um Perfil de atividade para continuar.
            </s.NoProfilesSubtext>
        </s.NoProfilesContainer>
    );
};

export const ThirdStep = ({ nextStep, prevStep, step }: TThirdStepProps) => {
    const [isCreationDialogOpen, setIsCreationDialogOpen] =
        useState<boolean>(false);
    const [isResponsibleDialogOpen, setOpenModalResponsible] = useState(false);
    const [responsibles, setResponsibles] = useState<NewResponsible | null>(
        null
    );
    const [profiles, setProfiles] = useState<TBudgetProfiles>([]);
    const { user } = useUser();

    const readProfiles = useQuery<
        TReadBudgetsProfiles,
        {
            length: 100;
            page: 1;
        },
        TReadBudgetsProfilesResponse
    >({
        queryKey: ["readProfiles"],
        queryFn: () =>
            Budgets.readProfiles({
                length: 100,
                page: 1,
                userId: user.id_e,
            }),
        refetchOnMount: "always",
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
        enabled: true,
    });

    const { isLoading } = readProfiles;

    const profileRef = useRef<number | null>(null);

    const { control, getValues, setValue } = useForm<ThirdStepData>({
        defaultValues: {
            budgetProfiles: profiles,
        },
    });

    const { fields: profilesFields } = useFieldArray({
        control,
        name: "budgetProfiles",
    });

    const handleNextStep = () => {
        const selectedProfiles = getValues("budgetProfiles").filter(
            (profile) => profile.isSelected === true
        );
        const validateSelectedProfilesExistence = selectedProfiles.length === 0;

        const validateSelectedProfilesHaveResponsibles = selectedProfiles.some(
            (profile) => profile.responsible !== null
        );

        if (validateSelectedProfilesExistence)
            return toast.error(
                "Você precisa selecionar ao menos um perfil de atividade "
            );

        if (!validateSelectedProfilesHaveResponsibles)
            return toast.error(
                "Os perfis de atividade selecionados, devem possuir um responsável"
            );

        return nextStep({ budgetProfiles: selectedProfiles });
    };

    const addProfile = async (newProfile: NewProfile) => {
        const bodyReq = {
            name: newProfile.name,
            abbreviation: newProfile.abbreviation,
        };

        const tempHeaders = {
            auth: true,
            "x-user-id": user.id_e,
        };

        await axiosHttpClient({
            url: `${REACT_APP_BASE_URL}/budget/profile`,
            method: "post",
            body: bodyReq,
            headers: tempHeaders,
        }).then(() => {
            readProfiles.refetch();
        });
    };

    const handleAddResponsibles = (responsibles: NewResponsible | null) => {
        if (profileRef.current === null) return;
        const currentProfiles = getValues("budgetProfiles");

        const updatedProfiles = currentProfiles.map((profile, index) => {
            return index === (profileRef.current as number)
                ? { ...profile, responsible: responsibles }
                : profile;
        });

        setValue("budgetProfiles", updatedProfiles as BudgetProfiles[]);
    };

    const handleModalResponsible = useCallback(
        (index?: number) => {
            if (isResponsibleDialogOpen) {
                return setOpenModalResponsible(false);
            }
            if (typeof index === "number") {
                const responsibleData = getValues(
                    `budgetProfiles.${index}.responsible`
                );
                profileRef.current = index;

                setResponsibles(responsibleData || null);
            }
            return setOpenModalResponsible(true);
        },
        [isResponsibleDialogOpen]
    );

    const handleSelectProfile = useCallback(
        (index: number) => {
            const prevData = getValues("budgetProfiles");

            const updatedProfiles = prevData.map((profile, i) => {
                return i === index
                    ? { ...profile, isSelected: !profile.isSelected }
                    : profile;
            });

            setValue("budgetProfiles", updatedProfiles as BudgetProfiles[]);
        },
        [profiles, control, setValue]
    );

    useEffect(() => {
        if (readProfiles.error) toast.error(`${readProfiles.error}`);
        if (readProfiles.data) {
            setProfiles(readProfiles.data);

            const profilesData = readProfiles.data.map((profile: any) => {
                return {
                    ...profile,
                    cost: 0,
                    responsible: null,
                    isSelected: false,
                };
            });
            setValue("budgetProfiles", profilesData);
        }
    }, [readProfiles.data]);

    useEffect(() => {
        if (user.id_e) {
            readProfiles.refetch();
        }
    }, [user.id_e]);

    return (
        <>
            {!isLoading && (
                <NewProfilesTable
                    handleModalResponsible={handleModalResponsible}
                    handleSelectProfile={handleSelectProfile}
                    control={control}
                    profiles={profilesFields}
                />
            )}
            {profiles.length === 0 && <NoProfiles />}
            <s.ProfileCreationButton
                onClick={(e) => {
                    e.preventDefault();
                    setIsCreationDialogOpen(true);
                }}
            >
                <s.CreationButtonTexts>
                    <Icons.Plus color={theme.purple500} strokeWidth="3" />
                    ADICIONAR NOVO PERFIL
                </s.CreationButtonTexts>
            </s.ProfileCreationButton>
            <NewProfileDialog
                open={isCreationDialogOpen}
                setOpen={setIsCreationDialogOpen}
                addProfile={addProfile}
            />
            <ResponsibleDialog
                open={isResponsibleDialogOpen}
                onClose={handleModalResponsible}
                addResponsibles={handleAddResponsibles}
                responsibles={responsibles}
                setResponsibles={setResponsibles}
            />
            <sScreen.FullLine />
            <Accordion sx={{ width: "100%" }}>
                <AccordionSummary expandIcon={<Icons.Chevron />}>
                    Como Funciona?
                </AccordionSummary>
                <AccordionDetails>
                    <sScreen.InfoText>
                        Perfis de Atividade permitem definir os profissionais
                        envolvidos em um orçamento, garantindo um planejamento
                        detalhado dos custos e responsabilidades. Esse recurso
                        auxilia na transparência da alodação de recursos e
                        otimiza a gestão do orçamento do projeto.
                    </sScreen.InfoText>
                    <sScreen.InfoText>
                        <Icons.Worker
                            fill={theme.gray600}
                            color={theme.gray600}
                        />
                        <sScreen.InfoTextHighlight>
                            Perfil de Atividade: Representa o tipo de
                            profissional envolvido no orçamento (Exemplo: UX
                            Designer, Desenvolvedor Backend)
                        </sScreen.InfoTextHighlight>
                    </sScreen.InfoText>
                    <sScreen.InfoText>
                        <Icons.Portrait
                            color={theme.gray600}
                            fill={theme.gray600}
                        />
                        <sScreen.InfoTextHighlight>
                            Responsável: Define o gestor que será notificado
                            sobre as horas desse perfil e ficará encarregado de
                            auxiliar e Estimar as horas.
                        </sScreen.InfoTextHighlight>
                    </sScreen.InfoText>
                    <sScreen.InfoText>
                        <Icons.Dollar color={theme.gray600} />
                        <sScreen.InfoTextHighlight>
                            Custo/Hora: Estabelece o valor por hora de cada
                            perfil profissional, permitindo calcular o impactor
                            financeiro no orçamento final.
                        </sScreen.InfoTextHighlight>
                    </sScreen.InfoText>
                    <sScreen.InfoText>
                        Essa abordagem facilita a gestão orçamentária,
                        garantindo que cada perfil tenha um acompanhamento
                        adequado e que o custo do projeto seja estruturado de
                        forma eficiente.
                    </sScreen.InfoText>
                </AccordionDetails>
            </Accordion>
            <StepButtons
                handleNextStep={handleNextStep}
                handlePrevStep={prevStep}
                step={step}
            />
        </>
    );
};
