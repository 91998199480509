import { Box, TextField, TextFieldProps, Typography } from "@mui/material";
import { Controller, Control, FieldValues, Path } from "react-hook-form";

type CustomTextFieldProps<T extends FieldValues> = TextFieldProps & {
    name: Path<T>;
    control: Control<T>;
    required?: boolean;
    maxCharacters?: number;
    formatValue?: (value: string) => string;
    isCurrency?: boolean;
};

const CustomTextField = <T extends FieldValues>({
    name,
    control,
    required = false,
    maxCharacters,
    formatValue,
    isCurrency = false,
    ...textFieldProps
}: CustomTextFieldProps<T>) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={{
                required: required ? "Campo obrigatório" : false,
            }}
            render={({ field, fieldState: { error } }) => (
                <Box sx={{ position: "relative" }}>
                    <TextField
                        {...field}
                        {...textFieldProps}
                        error={!!error}
                        inputProps={{
                            maxLength: maxCharacters,
                        }}
                        onChange={(e) => {
                            let rawValue = e.target.value;
                            rawValue = formatValue
                                ? formatValue(rawValue)
                                : rawValue;

                            const formattedValue = isCurrency
                                ? `R$ ${rawValue.replace(/[^\d,]/g, "")}`
                                : rawValue;

                            field.onChange(formattedValue);
                        }}
                        value={
                            isCurrency && field.value
                                ? `R$ ${field.value.replace(/[^\d,]/g, "")}`
                                : field.value
                        }
                    />
                    {error && (
                        <Typography
                            variant="subtitle1"
                            color="red"
                            sx={{ position: "absolute", bottom: "-1.5rem" }}
                        >
                            {error.message}
                        </Typography>
                    )}
                </Box>
            )}
        />
    );
};

export default CustomTextField;
