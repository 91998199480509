import { axiosHttpClient } from "../../axios";
import handleStatusCases from "../../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TReadBudgetTypes = {
    name?: string;
};

export type TReadBudgetTypesResponse = {
    id_e: string;
    name: string;
};

export default async (params: TReadBudgetTypes = {}) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/type`,
        method: "get",
        params,
        headers: { auth: true },
    });
    const { body, statusCode } = httpResponse;

    return handleStatusCases<TReadBudgetTypesResponse[]>({
        body,
        statusCode,
    });
};
