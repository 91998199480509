import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, width, onClick }: TBaseSVG) => {
    return (
        <svg
            onClick={onClick}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className || "chevron"}
        >
            <title>{title}</title>
            <path
                d="M19 9L12 16L5 9"
                stroke={color || theme.purple400}
                strokeWidth={width || "1.2"}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
