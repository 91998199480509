import { Breadcrumbs, Link, Typography } from "@mui/material";

type BreadCrumbsMUIProps = {
    breadcrumpRotes: {
        href: string;
        label: string;
    }[];
    currentRote: string;
};

export default function BreadCrumbsMUI({
    breadcrumpRotes,
    currentRote,
}: BreadCrumbsMUIProps) {
    return (
        <Breadcrumbs
            separator="›"
            aria-label="breadcrumb"
            sx={{ color: "black" }}
        >
            {breadcrumpRotes.map((breadcrumb) => (
                <Link
                    key={breadcrumb.href}
                    underline="hover"
                    color="inherit"
                    href={breadcrumb.href}
                    fontFamily="noto-sans"
                >
                    {breadcrumb.label}
                </Link>
            ))}
            <Typography fontFamily="noto-sans" color="black">
                {currentRote}
            </Typography>
        </Breadcrumbs>
    );
}
