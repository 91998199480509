import { useEffect, useState } from "react";

import { Icons, theme } from "../../assets";
import { useFetch } from "../../hooks";
import { countBudgets, readBudgets } from "../../services/budget";
import {
    TCountBudgets,
    TCountBudgetsResponse,
} from "../../services/budget/count-budgets";
import { TReadBudgetsResponse } from "../../services/budget/read-budgets";
import * as s from "./styled-finances-home";
import { TabBudget } from "./tab-budget/tab-budget";

export const BudgetsHome = () => {
    const [tab, setTab] = useState<"budget" | "activity_profiles">("budget");
    const [budgets, setBudgets] = useState<TReadBudgetsResponse[]>([]);
    const [filter, setFilter] = useState<string>("");
    const [budgetsCount, setBudgetsCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const readBudgetsFetch = useFetch({
        fn: readBudgets,
        params: {
            page: currentPage,
            length: 10,
            filter,
        },
    });
    const countBudgetsFetch = useFetch<
        TCountBudgets,
        TCountBudgetsResponse,
        any
    >({
        fn: countBudgets,
        params: { filter },
    });

    useEffect(() => {
        if (readBudgetsFetch.response) setBudgets(readBudgetsFetch.response);
    }, [readBudgetsFetch.response]);

    useEffect(() => {
        if (countBudgetsFetch.response)
            setBudgetsCount(countBudgetsFetch.response.count);
    }, [countBudgetsFetch.response]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            readBudgetsFetch.onRefresh({
                page: 1,
                length: 10,
                filter,
            });
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [filter]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            readBudgetsFetch.onRefresh({
                page: currentPage,
                length: 10,
                filter,
            });
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    });

    return (
        <s.Container>
            <s.Title>Orçamento</s.Title>
            <s.TabContainer>
                <s.Tab
                    active={tab === "budget"}
                    onClick={() => setTab("budget")}
                >
                    <Icons.Bill
                        color={
                            tab === "budget" ? theme.purple400 : theme.gray600
                        }
                        fill={
                            tab === "budget" ? theme.purple400 : theme.gray600
                        }
                    />
                    <strong>ORÇAMENTOS</strong>
                </s.Tab>
                <s.Tab
                    active={tab === "activity_profiles"}
                    onClick={() => setTab("activity_profiles")}
                >
                    <Icons.Worker
                        color={
                            tab === "activity_profiles"
                                ? theme.purple400
                                : theme.gray600
                        }
                        fill={
                            tab === "activity_profiles"
                                ? theme.purple400
                                : theme.gray600
                        }
                    />
                    <strong>PERFIS DE ATIVIDADE</strong>
                </s.Tab>
            </s.TabContainer>
            {tab === "budget" && (
                <TabBudget
                    filterState={[filter, setFilter]}
                    budgets={budgets}
                    budgetsCountState={[budgetsCount, setBudgetsCount]}
                    currentPageState={[currentPage, setCurrentPage]}
                />
            )}
        </s.Container>
    );
};
