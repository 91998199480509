import { Avatar, IconButton, Stack, Typography } from "@mui/material";
import { Control } from "react-hook-form";

import { CheckBox } from "../../../../components";
import CustomTextField from "../../../../components/molecules/custom-textfield/custom-textfield";
import { onlyNumbers } from "../../../../utils";
import * as s from "./styled-third-step";
import { BudgetProfiles } from "./third-step";

type TProfileTableProps = {
    control: Control<any>;
    handleModalResponsible: (id: number) => void;
    handleSelectProfile: (index: number) => void;
    profiles: BudgetProfiles[];
};

export const NewProfilesTable = ({
    control,
    handleModalResponsible,
    handleSelectProfile,
    profiles,
}: TProfileTableProps) => {
    return (
        <s.Table>
            <tr>
                <th>Perfil de atividade</th>
                <th>Responsável</th>
                <th>Custo/Hora</th>
            </tr>
            {profiles.map((profile, i) => {
                const costPrefix = `profiles.${i}.cost`;
                const { responsible } = profile;
                return (
                    <tr
                        style={{
                            height: "5.5rem",
                        }}
                    >
                        <td>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    display: "flex",
                                    gap: "1rem",
                                    alignItems: "center",
                                }}
                            >
                                <CheckBox
                                    id={`profile-selected`}
                                    width={18}
                                    height={18}
                                    isChecked={profile.isSelected}
                                    onChange={() => handleSelectProfile(i)}
                                />{" "}
                                {profile.name} ({profile.abbreviation})
                            </Typography>
                        </td>
                        <td>
                            {responsible !== null ? (
                                <Stack key={responsible?.name}>
                                    <IconButton
                                        onClick={() =>
                                            handleModalResponsible(i)
                                        }
                                        sx={{
                                            width: "3rem",
                                            height: "3rem",
                                        }}
                                    >
                                        {responsible?.avatar ? (
                                            <img
                                                src={`${responsible.avatar}`}
                                                alt={responsible.name}
                                                style={{
                                                    width: "2.5rem",
                                                    height: "2.5rem",
                                                    borderRadius: "50%",
                                                }}
                                            />
                                        ) : (
                                            <Avatar
                                                sx={{
                                                    height: "2.5rem",
                                                    width: "2.5rem",
                                                }}
                                            />
                                        )}
                                    </IconButton>
                                    <Typography variant="subtitle1">
                                        {responsible.name}
                                    </Typography>
                                </Stack>
                            ) : (
                                <IconButton
                                    onClick={() => handleModalResponsible(i)}
                                >
                                    <Avatar
                                        sx={{
                                            height: "2.5rem",
                                            width: "2.5rem",
                                        }}
                                    />
                                </IconButton>
                            )}
                        </td>
                        <td>
                            <CustomTextField
                                control={control}
                                name={costPrefix}
                                sx={{
                                    width: "100%",
                                }}
                                formatValue={onlyNumbers}
                                isCurrency
                            />
                        </td>
                    </tr>
                );
            })}
        </s.Table>
    );
};
