import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TReadBudgets = {
    filter?: string;
    page: number;
    length: number;
};

export type BudgetStatus = "approved" | "refused" | "waiting" | "progress";

export type TReadBudgetsResponse = {
    id_e: string;
    name: string;
    color: string;
    secondary_color: string;
    status: BudgetStatus;
    description: string;
    client: {
        id_e: string;
        name: string;
    };
    organization: {
        id_e: string;
        name: string;
    };
    Profiles: {
        id_e: string;
        name: string;
        costPerHour: number;
    }[];
    type: {
        id_e: string;
        name: string;
    };
    created_at: string;
    updated_at: string;
};

export default async (params: TReadBudgets = { length: 10, page: 1 }) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget`,
        method: "get",
        params,
        headers: { auth: true },
    });
    const { body, statusCode } = httpResponse;

    return handleStatusCases<TReadBudgetsResponse[]>({
        body,
        statusCode,
    });
};
