import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({
    title,
    color,
    className,
    strokeWidth,
    onClick,
}: TBaseSVG) => (
    <svg
        onClick={onClick}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className || "icon-plus"}
    >
        <title>{title}</title>
        <path
            d="M9 1.5V16.5M16.5 9H1.5"
            stroke={color || theme.purple600}
            strokeWidth={strokeWidth || "1.5"}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
