import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    TextField,
} from "@mui/material";
import { useState } from "react";

import { Icons, theme } from "../../../../assets";
import { StepButtons } from "../step-buttons";
import * as s from "../styled-form-screen";

type LevelOrder = 1 | 2 | 3;

type LevelConfig = {
    name: string;
    levelOrder: LevelOrder;
};

export type SecondStepData = {
    levelConfigs: LevelConfig[];
};

type TSecondStepProps = {
    nextStep: (currentData: SecondStepData) => void;
    prevStep: () => void;
    step: number;
};

export const SecondStep = ({ nextStep, prevStep, step }: TSecondStepProps) => {
    const [usingExtraLevel, setUsingExtraLevel] = useState<boolean>(false);
    const [levelConfigs, setLevelConfigs] = useState<LevelConfig[]>([]);

    const changeLevelConfig = (index: number, levelName: string) => {
        const copiedLevelConfigs = [...levelConfigs];
        copiedLevelConfigs[index] = {
            levelOrder: (index + 1) as LevelOrder,
            name: levelName,
        };

        setLevelConfigs(copiedLevelConfigs);
    };

    const removeExtraLevel = () => {
        delete levelConfigs[2];
        setUsingExtraLevel(false);
    };

    const handleNextStep = () => {
        nextStep({ levelConfigs });
    };

    return (
        <>
            <s.FormGrouper>
                {!usingExtraLevel && (
                    <>
                        {Array(2)
                            .fill(0)
                            .map((_, i) => {
                                return (
                                    <>
                                        <TextField
                                            label={`Nível ${i + 1}`}
                                            value={levelConfigs[i]?.name}
                                            onChange={(e) =>
                                                changeLevelConfig(
                                                    i,
                                                    e.target.value
                                                )
                                            }
                                            sx={{ width: "37.5%" }}
                                        />
                                    </>
                                );
                            })}
                        <s.NewLevelCreationButton
                            onClick={() => setUsingExtraLevel(true)}
                        >
                            <Icons.Plus />
                            <strong>ADICIONAR NOVO NÍVEL</strong>
                        </s.NewLevelCreationButton>
                    </>
                )}
                {usingExtraLevel &&
                    Array(3)
                        .fill(0)
                        .map((_, i) => {
                            return (
                                <>
                                    <TextField
                                        label={`Nível ${i + 1}`}
                                        value={levelConfigs[i]?.name}
                                        onChange={(e) =>
                                            changeLevelConfig(i, e.target.value)
                                        }
                                        sx={{ width: "32%" }}
                                        InputProps={{
                                            endAdornment:
                                                i === 2 ? (
                                                    <Icons.Trash
                                                        onClick={() =>
                                                            removeExtraLevel()
                                                        }
                                                    />
                                                ) : null,
                                        }}
                                    />
                                </>
                            );
                        })}
            </s.FormGrouper>
            <s.FullLine />
            <Accordion sx={{ width: "100%" }}>
                <AccordionSummary expandIcon={<Icons.Chevron />}>
                    Como Funciona?
                </AccordionSummary>
                <AccordionDetails>
                    <s.InfoText>
                        A funcionalidade de Níveis de Escopo permite organizar
                        informações hierárquicas dentro de um projeto,
                        proporcionando flexibilidade para diferentes setores e
                        cenários de uso.
                    </s.InfoText>
                    <s.InfoText>
                        Essa estrutura pode conter dois ou três níveis,
                        dependendo da complexidade do projeto e do tipo de
                        informação que precisa ser gerenciada
                    </s.InfoText>
                    <s.InfoTextExample>
                        <s.InfoText>
                            <Icons.Dev
                                fill={theme.gray600}
                                width="32"
                                height="32"
                            />
                            <div>
                                Desenvolvimento de Software (2 níveis)
                                <br /> Nível 1: Requisitos Funcionais
                                <br /> Nível 2: Features dentro de cada
                                requisito
                            </div>
                        </s.InfoText>
                        <s.InfoText>
                            Exemplo:
                            <br />
                            Requisito Funcional: Autenticação de Usuário
                            <br /> Feature: Login com email e senha
                            <br /> Feature: Login via redes sociais
                        </s.InfoText>
                    </s.InfoTextExample>
                    <s.InfoTextExample>
                        <s.InfoText>
                            <Icons.Car color={theme.gray600} />
                            <div>
                                Manutenção de Veículos (3 níveis)
                                <br /> Nível 1: Oficina
                                <br /> Nível 2: Tipo de Veículo
                                <br /> Nível 3: Peças ou Serviços aplicados
                            </div>
                        </s.InfoText>
                        <s.InfoText>
                            Exemplo:
                            <br /> Oficina: Mecânica Geral
                            <br /> Tipo de Veículo: Carro
                            <br /> Serviço: Troca de óleo
                        </s.InfoText>
                    </s.InfoTextExample>
                    <s.InfoText>
                        Essa abordagem garante que qualquer área possa
                        estruturar seu escopo de forma lógica e fundamental,
                        permitindo que equipes e gestores tenham um melhor
                        controle sobre suas demandas.
                    </s.InfoText>
                </AccordionDetails>
            </Accordion>
            <StepButtons
                handleNextStep={handleNextStep}
                handlePrevStep={prevStep}
                step={step}
            />
        </>
    );
};
