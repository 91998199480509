import { TextField } from "@mui/material";
import { useEffect, useState } from "react";

import { theme } from "../../../../assets";
import ColorPicker, {
    PickableColor,
} from "../../../../components/atoms/color-picker/color-picker";
import { useFetch } from "../../../../hooks";
import { TGetClient } from "../../../../models";
import readBudgetTypes, {
    TReadBudgetTypes,
    TReadBudgetTypesResponse,
} from "../../../../services/budget/type/read-budget-types";
import { getClients } from "../../../../services/clients";
import { TGetClientResponse } from "../../../../services/clients/get-clients";
import { StepButtons } from "../step-buttons";
import * as s from "../styled-form-screen";
import { ClientOption, ClientSelect } from "./client-select";
import { BudgetTypeOption, TypeSelect } from "./type-select";

export type FirstStepData = {
    name: string;
    budgetTypeId: string;
    color: PickableColor;
    secondaryColor: string;
    clientId: string;
    description: string;
};

type TFindStep = {
    nextStep: (currentData: FirstStepData) => void;
    prevStep: () => void;
    step: number;
};

const FirstStep = ({ nextStep, prevStep, step }: TFindStep) => {
    const [name, setName] = useState<string>("");
    const [type, setType] = useState<string>("");
    const [color, setColor] = useState<PickableColor | "">("");
    const [client, setClient] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [budgetTypes, setBudgetTypes] = useState<BudgetTypeOption[]>([]);
    const [clients, setClients] = useState<ClientOption[]>([]);

    const readBudgetTypeFetch = useFetch<
        TReadBudgetTypes,
        TReadBudgetTypesResponse[],
        any
    >({
        fn: readBudgetTypes,
        params: {},
    });

    useEffect(() => {
        if (readBudgetTypeFetch.response) {
            const responseWithOptions: BudgetTypeOption[] =
                readBudgetTypeFetch.response.map(
                    (bt) =>
                        ({
                            ...bt,
                            options: [
                                {
                                    label: bt.name,
                                    value: bt.id_e,
                                },
                            ],
                        } satisfies BudgetTypeOption)
                );

            setBudgetTypes(responseWithOptions);
        }
    }, [readBudgetTypeFetch.response]);

    const getClientsFetch = useFetch<TGetClient, TGetClientResponse[], any>({
        fn: getClients,
        params: { active: true },
    });

    useEffect(() => {
        if (getClientsFetch.response) {
            const responseWithOptions: ClientOption[] =
                getClientsFetch.response.map(
                    (bt) =>
                        ({
                            ...bt,
                            options: [
                                {
                                    label: bt.name,
                                    value: bt.id_e,
                                },
                            ],
                        } satisfies BudgetTypeOption)
                );

            setClients(responseWithOptions);
        }
    }, [getClientsFetch.response]);

    const getSecondaryColor = (color: PickableColor): string => {
        const SECONDARY_COLOR_MAP = {
            "#03BA78": "#a9d4c4",
            "#0B49EC": "#abc2ff",
            "#0CB8B8": "#b5ffff",
            "#278027": theme.lightGreen,
            "#333333": "#d1d1d1",
            "#4B07BF": "#cbb3f5",
            "#800380": "#b594b5",
            "#824700": "#e8c090",
            "#8BB908": "#cee09b",
            "#9933FF": theme.purple100,
            "#9D0000": "#e8a7a7",
            "#D14141": theme.softRed,
            "#DD04DD": theme.pink200,
            "#F28500": "#e0caaf",
            "#FF015C": theme.pink100,
        } satisfies Record<PickableColor, string>;

        return SECONDARY_COLOR_MAP[color];
    };

    const handleNextStep = () => {
        if (color === "") return;

        const currentData = {
            name,
            budgetTypeId: type,
            color,
            secondaryColor: getSecondaryColor(color),
            clientId: client,
            description,
        } satisfies FirstStepData;

        nextStep(currentData);
    };

    return (
        <>
            <s.FormGrouper className="space-between">
                <TextField
                    label="Nome do Orçamento"
                    onChange={(e) => setName(e.target.value)}
                    sx={{ width: "49.5%" }}
                />
                <s.SelectContainer className="half">
                    <TypeSelect
                        budgetTypes={budgetTypes}
                        setType={setType}
                        refetch={readBudgetTypeFetch.onRefresh}
                    />
                </s.SelectContainer>
            </s.FormGrouper>
            <s.SelectContainer>
                <ClientSelect clients={clients} setClient={setClient} />
            </s.SelectContainer>
            <TextField
                placeholder="Digite aqui uma pequena descrição sobre este projeto"
                sx={{ width: "100%" }}
                onChange={(e) => setDescription(e.target.value)}
            />
            <s.FormGrouper className="flex-start">
                <span>
                    Defina a cor da tag do seu projeto selecionando um dos
                    quadrados de cores
                </span>
            </s.FormGrouper>
            <s.FormGrouper className="flex-start">
                <ColorPicker
                    onSelect={setColor}
                    title="Escolher a cor da tag"
                />
            </s.FormGrouper>
            <StepButtons
                handleNextStep={handleNextStep}
                handlePrevStep={prevStep}
                step={step}
            />
        </>
    );
};

export { FirstStep };
